'use strict';

var dom = require('portfolio-dom');

var scrollApi;
var $page;
var $pageScroller;


// Update the page height in case this template added margin. Margin
// is used a lot in full screen templates, for example.
var setPageHeight = function() {

	var pageMarginTop = parseInt($page.css('marginTop'), 10);
	var pageMarginBottom = parseInt($page.css('marginBottom'), 10);
	var pageMarginMin = window.template.data.layout.minScrollerMargin || 0;

	// Some templates dynamically determine the margin necessary to avoid element
	// collisions. Look for this data and if found, use it.

	pageMarginTop = pageMarginTop < pageMarginMin ? pageMarginMin : pageMarginTop;
	pageMarginBottom = pageMarginBottom < pageMarginMin ? pageMarginMin : pageMarginBottom;

	$page.css({
		marginTop: pageMarginTop,
		marginBottom: pageMarginBottom,
		height: 'calc(100% - ' + (pageMarginTop + pageMarginBottom) + 'px)'
	});

};

module.exports = {

	init: function() {

		$page = dom.content.find('.page');
		$pageScroller = $page.find('.page__scroller');

		setPageHeight();

		$pageScroller.jScrollPane({
			verticalGutter: 15,
			hideFocus: true,
			// Hack to disable horizontal scroll bars.
			contentWidth: '0px'
		});

		scrollApi = $pageScroller.data('jsp');

		// Proxy scroller events to keep them generic for the rest of the app.
		$pageScroller.on('jsp-scroll-y', function(e, yOffset) {

			window.core.triggerCustomEvent({
				type: 'scroller_scroll_y',
				yOffset: yOffset
			});

		});

		// Refresh to adjust for any css hook adjustments.
		dom.document.on('core_css_hooks_added.scroller', this.refresh);

		// Refresh to adjust if custom fonts were not applied the first time.
		// Fixes chrome and safari issue.
		dom.document.on('core_page_reveal_begin.scroller core_page_reveal_complete.scroller', this.refresh);

		dom.window.on('resize.scroller', this.refresh);

		window.core.addUnload(this.destroy);

	},

	refresh: function() {

		var isScrollable = scrollApi.getIsScrollableV();

		setPageHeight();
		scrollApi.reinitialise();

		if (isScrollable !== scrollApi.getIsScrollableV()) {

			// Scroll bars were either added or removed.

			window.core.triggerCustomEvent({
				type: 'scroller_scrollable_change',
				isScrollable: !isScrollable
			});

		}

	},

	destroy: function() {

		dom.window.off('.scroller');
		dom.document.off('.scroller');

	}

};
