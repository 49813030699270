'use strict';

require('jquery-migrate');
require('jquery-jscrollpane');
require('jquery-cookie');
require('jquery-mousewheel');
require('jquery-color');
require('jquery-history');
require('jquery-jplayer');
require('jqmodal');
require('portfolio-jquery-ui');
require('portfolio-modernizr');

// Begin non browserify custom js.
require('portfolio-phoenix');
require('portfolio-core-util');
require('portfolio-core-dom');
require('portfolio-colormanager');
require('portfolio-effects');
require('portfolio-fader');
require('portfolio-calendar');
require('portfolio-music');
require('portfolio-scroller');
require('portfolio-modal');
require('portfolio-slideshow');
require('portfolio-slideshow-images');
require('portfolio-slideshow-standard');
require('portfolio-slideshow-reel');
require('portfolio-slideshow-captions');
require('portfolio-slideshow-autoplay');
require('portfolio-slideshow-control-thumbs');
require('portfolio-slideshow-control-buttons');
require('portfolio-slideshow-control-slider');
// End non browserify custom js.

// var focalPoint = require('focal-point');
var dom = require('portfolio-dom');
var form = require('portfolio-form');
var scroller = require('portfolio-scroller');
// var gridLayout = require('portfolio-grid-layout');

module.exports = {

	// Expose new browserify modules to legacy js.
	// focalPoint: focalPoint,
	dom: dom,
	form: form,
	scroller: scroller/*,
	gridLayout: gridLayout*/

};
