
; require("/srv/www/html/client/modules/vendor/underscore/underscore.js");
; var __browserify_shim_require__=require;(function browserifyShim(module, define, require) {
//-------------------------------------------------------------------------
//	Define underscore.js mixins
//-------------------------------------------------------------------------

	/**
	 *	Shortcut for console.log
	 *	Example: _.log(var1, var2)
	 */

	_.mixin({

		log: function() {

			if (window.console && core.data.showLog) {

				_.each(arguments, function(el) {
					console.log(el);
				});

			}

		},

		// function to determine what color control images to use
		blackOrWhite: function(color) {

			function diff(color1, color2) {

				function toRGB(hex) {

					hex = hex.replace(/#/g, '');

					return [
						parseInt(hex.substring(0, 2), 16),
						parseInt(hex.substring(2, 4), 16),
						parseInt(hex.substring(4, 6), 16)
					];

				}

				// most of the time, jQuery will return a css color as rgb(...) so it will need
				// to be converted to hex; seems odd to convert from rgb string to hex and
				// back to rgb, but the data types are different and I already had toRGB flow working
				function toHex(rgb) {

					var parts = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

					delete (parts[0]);

					for (var i = 1; i <= 3; ++i) {

						parts[i] = parseInt(parts[i]).toString(16);

					    if (parts[i].length == 1) {
					    	parts[i] = '0' + parts[i];
					    }

					}

					return '#' + parts.join('').toUpperCase();

				}

				if (color1.indexOf('rgb(') > -1) {
					color1 = toHex(color1);
				}

				if (color2.indexOf('rgb(') > -1) {
					color2 = toHex(color2);
				}

				color1 = toRGB(color1);
				color2 = toRGB(color2);

				return Math.max(color1[0], color2[0]) - Math.min(color1[0], color2[0]) + Math.max(color1[1], color2[1]) - Math.min(color1[1], color2[1]) + Math.max(color1[2], color2[2]) - Math.min(color1[2], color2[2]);

			}

			if (diff('000000', color) > diff('ffffff', color)) {
				return 'black';
			} else {
				return 'white';
			}

		},

		noRightClick: function(txt){

			$(function() {

		        $(window).bind('contextmenu', function(e) {
        		    e.preventDefault();
				});

			});

		},

		isEmail: function(str) {

			var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;

			return pattern.test(str);

		}

	});



//-------------------------------------------------------------------------
//	Define jQuery plugins
//-------------------------------------------------------------------------

	/**
	 *	Tests for the existence of an attribute
	 */

	$.fn.hasAttr = function(attr) {

    	for (var i = 0; i < this[0].attributes.length; i++) {

        	if (this[0].attributes[i].nodeName === attr) {
        		return true;
        	}

		}

		return false;

	};

	/**
	 *	Reverses a jquery "array"
	 */

	$.fn.reverse = function() {

		return this.pushStack(this.get().reverse(), arguments);

	};



//-------------------------------------------------------------------------
//	Declare main object for the phoenix application
//-------------------------------------------------------------------------

	// var core = {};



//-------------------------------------------------------------------------
//	Top-level data structures
//-------------------------------------------------------------------------

	/**
	 *	Holds general data
	 */
	core.data.showLog = true;
	core.data.pageID = undefined;
	core.data.runIntro = true;
	core.data.spinnerSize = 47;
	core.data.spinnerTimer = undefined;
	core.data.colorShiftEnabled = false;
	core.data.submenuHideDelay = 650;
	// core.data.hasRevealedContent = false;
	core.data.isTemplateIntroComplete = false;
	core.data.aspect = 0.5766666666666667;
	core.data.reverseAspect = 1.734104046242775;
	core.data.isPageReady = false;

	/**
	 *	Holds module references that need to have a cleanup function
	 *	called when leaving a page
	 */

	core.unload = [];

//-------------------------------------------------------------------------
//	Functions for initialization, navigating between pages, etc.
//-------------------------------------------------------------------------

	core.addUnload = function(fn) {

		core.unload.push(fn);

	};



//-------------------------------------------------------------------------
//	Functions for initialization, navigating between pages, etc.
//-------------------------------------------------------------------------

	core.deactivateMenu = function(){
		$(document).on("click.disable mouseenter.disable mouseleave.disable", "*", function(e){
			e.preventDefault();
			e.stopImmediatePropagation();
		});
	};

	core.deactivateMenu();

	/**
	 *	Initializes the site when first loaded
	 */






	core.init = function() {

		/*

		MOVED INTO CORE.DOM MODULE:

		core.dom.body = $("body");
		core.dom.spinner = $('#spinner');
		core.dom.content = $('#content');
		core.dom.viewer = $('#viewer');
		core.dom.logo = $('#logo');
		core.dom.icons = $('#icons');
		core.dom.footer = $('#footer');
		core.dom.footerText = $('#footerText');
		core.dom.menu = $('#menu');
		core.dom.subwrappers = core.dom.menu.find(".sub-wrapper");
		core.dom.submenus = core.dom.menu.find(".subMenu");
		core.dom.menuLinks = $('.topLink');
		core.dom.submenuLinks = core.dom.menu.find('.subLink');
		core.dom.pbizbrand = $("#pbizbrand");
		core.dom.thumbs = $(".thumb");
		core.dom.thumbContainer = $("#thumbContainer");
		core.dom.menuBackground = $("<div id='menuBackground' />");
		core.dom.menuHover = $("<div id='menuHover' />");

		*/

		core.dom.init();

		core.data.history = [];
		core.data.touch = Modernizr.touch;
		core.data.submenuHideDelay = typeof template.data.menu.submenuHideDelay !== "undefined" ? template.data.submenuHideDelay : 650;
		core.data.pageTransition = typeof template.data.pageTransition !== "undefined" ? template.data.pageTransition : "none";
		core.data.activateHover = typeof template.data.menu.activateHover !== "undefined" ? template.data.menu.activateHover : false;
		core.data.activateBg = typeof template.data.menu.activateBg !== "undefined" ? template.data.menu.activateBg : false;
		core.data.autoAlignSubWrapper = typeof template.data.menu.autoAlignSubWrapper !== "undefined" ? template.data.menu.autoAlignSubWrapper : true;

		colorManager.init();
		core.positionSpinner("window"); // position spinner relative to window since we don't know size and location of content div yet

		if (window.location.search.search(/(\/|\?|&)page(id|ID)?[\/\=]{1,1}[0-9]+/i) >= 0) { // found a page ID in url

			var page;
			var parts = _.without(window.location.search.replace(/\?/, '').split(/[\/\?&\=]/), 'load', 'html');
			_.each(parts, function(el, i) {

				if (el.toUpperCase() === 'PAGE' || el.toUpperCase() === 'PAGEID') {
					page = parts.splice(i, 2);
				}

			});

			//_.log(parts, page);

			// don't redirect if page isn't valid (e.g. deleted or disabled)
			/*if (core.data.pages[page[1]] !== undefined) {

				// fix for extra url var with no value (e.g. SEO page title text)
				if (parts.length % 2 !== 0) {
					parts.pop();
				}

				// prepend load/html to fix splash intro issue
				parts.unshift('load', 'html');

				window.location.replace(
					window.location.protocol + '//' + window.location.host + window.location.pathname + (parts.length > 1 ? '?' + parts.join('/') + '/' : '') + '#!/page/' + page[1]
				);

			} else {
				core.processRequest('');
			}*/

			// Instead of not redirecting if the page isn't valid (commented
			// code above), do a redirect to the home page.

			// fix for extra url var with no value (e.g. SEO page title text)
			if (parts.length % 2 !== 0) {
				parts.pop();
			}

			// prepend load/html to fix splash intro issue
			parts.unshift('load', 'html');

			window.location.replace(
				window.location.protocol +
					'//'+
					window.location.host +
					window.location.pathname +
					(parts.length > 1 ? '?' + parts.join('/') + '/' : '') +
					'#!/page/' +
					(core.data.pages[page[1]] ? page[1] : core.data.userSettings.homePageId)
			);

		} else if (window.location.hash.search(/#\/[0-9]+/) >= 0) { // found a hash with no bang

			window.location.replace(
				window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.hash.replace(/#\/([0-9]+)/, '#!/page/$1')
			);

		} else if (window.location.hash.search(/^#!\/page\/[0-9]+/i) === -1) { // did not find a page ID
			core.processRequest('');
		}

		$('#menu a:not("ddmenu,.elink")').each(function() {
			$(this).attr('href', $(this).attr('href').replace(/\?/, '#!/'));
		});

		$.History.bind(function(state) {

			core.processRequest(core.cleanHash(state));

		});

		$(document).bind("template_intro_complete", function(){


			// _.log("intro_complete fired");

			core.data.isTemplateIntroComplete = true;
			core.reveal();
			core.positionSpinner(); // we now know content size and position, reposition spinner

			$(window).resize(core.positionSpinner);

			fader.init(core.data.userSettings.logoVisibility, core.data.userSettings.pbizBranding);

		});

		$(document).on("page_ready", function(){

			core.data.isPageReady = true;
			core.reveal();

		});

	};

	/**
	 *	Starts and shows loading spinner
	 */

	core.spinnerShow = function() {

		var current = 1;
		var next = 2;
		var prev = 12;

		core.data.spinnerTimer = window.setInterval(function() {

			$('.block').removeClass('loading-prev loading-next loading-current');
			$('#loading' + current).addClass('loading-current');
			$('#loading' + prev).addClass('loading-prev');
			$('#loading' + next).addClass('loading-next');

			if (current === 12) {

				current = 1;
				prev = 12;
				next = 2;

			} else {

				current++;
				next = current + 1;
				prev = current - 1;

			}

		}, 100);

		core.dom.spinner.show();

	};

	/**
	 *	Stops and hides loading spinner
	 */

	core.spinnerHide = function() {

		core.dom.spinner.hide();

		window.clearInterval(core.data.spinnerTimer);

	};

	/**
	 *	Runs the template's intro animation
	 */

	core.intro = function() {

		$('#phoenix,#content').removeClass('hidden');

		core.setMenuWidths();
		core.setupMenuBg();

		if (!core.data.touch) {

			core.setupMenuHover();

		}

		template.init();

		core.data.runIntro = false;

	};


	core.hideContent = function(trans){

		var duration = 400;

		core.triggerCustomEvent({
			type: "core_page_hide_begin"
		});

		switch(trans){

			default:
				$.when(core.dom.content.animate({ opacity: 0 }, duration), slideshow.runCleanup(duration)).then(function() {

					core.triggerCustomEvent({
						type: "core_page_hide_complete"
					});

					core.loadPage();

				});

				/*core.dom.content.animate({ opacity: 0 }, 400, function() {

					core.triggerCustomEvent({
						type: "core_page_hide_complete"
					});

					slideshow.runCleanup().then(function() {
						_.log("all cleanup items done...calling core.loadPage()");
						core.loadPage();
					});

				});*/

				break;

		}

	};

	core.revealContent = function(trans){

		switch(trans){

			default:
				core.triggerCustomEvent({
					type: "core_page_reveal_begin",
					pageData: core.getCurrentPage()
				});

				core.dom.content.animate({ opacity: 1 }, 400, function(){

					$(document).trigger("core_page_reveal_complete");

					if(core.pageHasGallerySupport() && !core.dom.body.hasClass("image_count_0")){ // only start the slideshow if the page supports galleries AND there is at least 1 image

						 core.triggerCustomEvent({
							type: "slideshow_start"
						});

					}

					core.activateMenu();

				});
				break;

		}

	};

	core.activateMenu = function(){
		$(document).off("click.disable mouseenter.disable mouseleave.disable");
	};

	/**
	 *	Reveals the main content when it's ready
	**/

	core.reveal = function() {


		// _.log("core.reveal called");
		// if(!core.data.hasRevealedContent){

			var trans = core.data.pageTransition;

			if(core.data.isTemplateIntroComplete && core.data.isPageReady){

				core.spinnerHide();
				core.revealContent(trans);
				// core.data.hasRevealedContent = true;

			}

		// }

	};

	/**
	 *	Adds a page's content and function to
	 *	the page store
	 *	Called by each page that gets requested
	 */

	core.addPage = function(pageID, align, fn) {

		/*_.log("core.addPage called");
		_.log(pageID);
		_.log(align);
		_.log(func);*/
		// _.log(core.data.pages);

		core.data.pageID = pageID;
		core.data.pages[pageID].html = $('#content').html();
		core.data.pages[pageID].align = align;
		core.data.pages[pageID].fn = fn;
		core.data.pages[pageID].pageID = pageID;

	};

	/**
	 *	Gets data for current page
	 */

	core.getPage = function(pageID) {

		// _.log("pageID: " + pageID);

		var pageID = typeof pageID !== 'undefined' && pageID != 0 ? pageID : core.data.pageID;
		var data = core.data.pages[pageID];

		// Keep compatibility with old 'contact' page type.
		data.type = data.type === 'form' ? 'contact' : data.type;

		return data;

	};

	core.getPageType = function(pageID) {

		return core.getPage(pageID).type;

	};

	core.isCalendarPage = function(pageID){
		return core.getPageType(pageID) === "calendar";
	}

	core.isInfoPage = function(pageID){
		return core.getPageType(pageID) === "info";
	}

	core.isGalleryPage = function(pageID){
		return core.getPageType(pageID) === "gallery";
	}

	core.isContactPage = function(pageID){
		return core.getPageType(pageID) === "contact";
	}

	core.isListPage = function(pageID){
		return core.getPageType(pageID) === "list";
	}

	core.isVideoPage = function(pageID){
		return core.getPageType(pageID) === "video";
	}

	core.getHomePage = function(){
		return core.getPage(core.getHomePageID());
	}

	core.getHomePageID = function(){
        // return core.data.userSettings.defaultPage;
		return core.data.userSettings.homePageId;
	}

	core.isHomePage = function(pageID){
		return parseInt(core.getHomePageID()) === parseInt(core.getPage(pageID).pageID);
	}

	core.addPageToHistory = function(){

		core.data.history.push(core.data.pageID);

		if(core.data.history.length > 5){
			core.data.history.shift();
		}

	};

	core.getCurrentPage = function(){
		return core.getPage();
	};

	core.getPreviousPage = function(){
		var idx = core.data.history.length - 2; // subtract 2 in order to get the second to last item in the array
		if(idx < 0){
			idx = 0;
		}
		var pageID = core.data.history[idx];
		return core.getPage(pageID);
	}

	core.getRequestedPageID = function(hash){
		var pageID;

		if (hash !== '') {

			pageID = core.getPageFromString(hash);

			if (core.data.pages[pageID] === undefined) {
				pageID = core.data.userSettings.defaultPage;
			}

		} else {
			pageID = core.data.userSettings.defaultPage;
		}
		return pageID;
	};

	/**
	 *	Processes a page request
	**/

	core.processRequest = function(hash) {

		// _.log("processRequest called");

		// core.data.hasRevealedContent = false;
		core.data.pageReady = false;
		core.data.pageID = core.getRequestedPageID(hash);
		core.data.pageName = core.getPageNameFromString(hash);
		core.addPageToHistory(core.data.pageID);
		core.notifyTemplateOfPageChange();
		core.runRequestCleanup();
		core.spinnerShow();
		core.hideContent(core.data.pageTransition);
		core.updateActiveMenuItems();

	};

	core.loadPage = function(){

        // console.log( location  );

		var isHtmlCached = false,
			isInitialLoad = core.data.runIntro,
			requestedPageID = core.data.pageID;

		// core.updateCssHooks();

		if (isInitialLoad) {
			core.intro();
			core.cachePage(); // always home page on initial load
			core.data.pageID = requestedPageID;
		}

		isHtmlCached = typeof core.getPage().html !== "undefined";

		if (isHtmlCached) {

			if(!isInitialLoad){ // only inject content if not initial load of site to home page - cuz if you're on the home page, the content is already in there!
				core.dom.content.html(core.getPage().html);
			}

			core.initPage();

		} else { // don't have html yet for this page

            // console.log( "making 2nd request for page id"  );
            // debugger;

			var params = location.search.substring(1).replace(/[&=]/g, '/');

			if (params.length) {
				params = '/' + params;
			}

			core.dom.content.load('?ajax/1' + params + '/page/' + core.data.pageID, function(){
				core.cachePage();
				core.initPage();
			});

		}
	};

	core.notifyTemplateOfPageChange = function(){

		if(template.loadPage){ // deprecated, remove this soon by replacing loadPage() on template level with event handler
			template.loadPage(core.getPreviousPage(), core.getCurrentPage());
		}

		$(document).trigger({ type: "core_page_load_start", prevPage: core.getPreviousPage(), nextPage: core.getCurrentPage() });

	};

	core.runRequestCleanup = function(){

		// if not first load, run cleanup
		if (!core.data.runIntro) {

			window.clearInterval(core.data.spinnerTimer);

			// run cleanup functions
			console.log('running cleanup functions...', core.unload);
			_.each(core.unload, function(el, i) {

				el.call();
				core.unload[i] = null;

			});

			core.unload = [];

		}

	};

	core.updateActiveMenuItems = function(){

		// _.log("updateActiveMenuItems called");

		core.dom.activeMenu = core.dom.menu.find('a[data-page="' + core.data.pageID + '"]');

		// KC - 09/20/12
		// start handling visible pages in hidden menus

		$('#hiddenPage').remove();
		$('.temp-ddmenu').removeClass('ddmenu temp-ddmenu');

		if (!core.dom.activeMenu.length) {

			// The page we're looking at (the current page) is not in the menu

			var temp = core.dom.menu.find('a[data-page="' + core.data.userSettings.defaultPage + '"]');

			if (temp.length) {

                // We found the "defaultPage" (actually the current page) in the dom.

                if (temp.hasAttr('data-parent')) {

                    // This page is a child of a drop down.

                    core.data.parentPageID = temp.data('parent');

                } else {

                    // This page is a top level page.

                    core.data.parentPageID = temp.data('page');

                }

            } else {

                // We couldn't find the "defaultPage" in the dom, so now look
                // for the "homePageId". This scenario arises when friendly
                // names are present in the url and we are trying to view
                // a hidden page.

                temp = core.dom.menu.find('a[data-page="' + core.data.userSettings.homePageId + '"]');

                if (temp.hasAttr('data-parent')) {

                    // This page is a child of a drop down.

                    core.data.parentPageID = temp.data('parent');

                } else {

                    // This page is a top level page.

                    core.data.parentPageID = core.data.userSettings.homePageId;

                }

            }

			core.dom.activeMenu = $('<a id="hiddenPage">').hide().appendTo('body');
			core.dom.activeParentMenu = core.dom.menu.find('a[data-page="' + core.data.parentPageID + '"]');

			if(core.isPageVisible() && temp.length && temp.hasAttr('data-parent')){
				core.dom.activeParentMenu.addClass('ddmenu temp-ddmenu');
			}

		// end handling visible pages in hidden menus

		} else {

			if (!core.dom.activeMenu.hasAttr('data-parent')) {

				core.data.parentPageID = core.data.pageID;
				core.dom.activeParentMenu = core.dom.activeMenu;

			} else {

				core.data.parentPageID = core.dom.activeMenu.attr('data-parent');
				core.dom.activeParentMenu = core.dom.menu.find('a[data-page="' + core.data.parentPageID + '"]');

			}

		}

		core.notifyTemplateOfMenuUpdates();

	};

	core.notifyTemplateOfMenuUpdates = function(){ // deprecated, replace these template level methods with custom event asap

		if (!core.data.runIntro) {

			if(template.menuMoveToParent){
				template.menuMoveToParent(core.dom.activeParentMenu, core.dom.activeMenu);
			}

			if(template.menuHighlightChild){
				template.menuHighlightChild();
			}

		}

	};

	core.positionSpinner = function(contextStr){

		var context = contextStr === "window" ? $(window) : core.dom.content;
			spinner = core.dom.spinner,
			spinnerHeight = spinner.height(),
			spinnerWidth = spinner.width(),
			startingTop = contextStr === "window" ? 0 : context.offset().top,
			startingLeft = contextStr === "window" ? 0 : context.offset().left;

		spinner.css({
			top: startingTop + (context.height() / 2) - (spinnerHeight / 2),
			left: startingLeft + (context.width() / 2) - (spinnerWidth / 2)
		});

	};

	core.initPage = function(){
		$(document).trigger({ type: "core_page_injected", pageData: core.getCurrentPage() });
		core.removeCssHooks();
		core.updatePageMetadata();
		core.getPage().fn.call();
		core.updateCssHooks();
		$(document).trigger({ type: "core_page_reveal_start", pageData: core.getCurrentPage() });
		core.registerPageLoad();
	};


	core.registerPageLoad = function(){
		if(typeof _gaq !== "undefined"){
			_gaq.push(["_trackPageview", "/page/" + core.pageData.pageID + "/" + core.data.pageName ]);
		}
	};

	core.updatePageMetadata = function(){

		var $keywords = core.dom.getElement({ key: "metaKeywords" }),
			$desc = core.dom.getElement({ key: "metaDesc" });

		$keywords.attr("content", core.pageData.metaKeywords);
		$desc.attr("content", core.pageData.metaDesc);

		document.title = core.pageData.title;

	};

	core.cachePage = function(){

		switch(core.pageData.pageType){

			case "gallery":
				core.cacheGalleryPage();
				break;

			case "info":
				core.cacheInfoPage();
				break;

			case "contact":
				core.cacheContactPage();
				break;

			case "calendar":
				core.cacheCalendarPage();
				break;

			case "list":
				core.cacheListPage();
				break;

			case "video":
				core.cacheVideoPage();
				break;

		}

	},

	/*core.getNoImg = function(){
		return [{
			width: 1500,
			height: 865,
			index: 0,
			xlarge: "/portfolio/images/no_images.jpg",
			large: "/portfolio/images/no_images.jpg",
			medium: "/portfolio/images/no_images.jpg",
			small: "/portfolio/images/no_images.jpg",
			thumb: "/portfolio/images/no_images.jpg",
			color: "",
			caption : ""
		}];
	};

	core.getSlideshowImgArr = function(imgData){
		if(!imgData.length){
			imgData = core.getNoImg();
		}
		return imgData;
	};*/

	core.getSlideshowSettings = function(pageData) {

		var settings = pageData.slideshow;

		settings.scaleMode = core.data.userSettings.scaleMode;
		settings.pageType = pageData.pageType;
		settings.align = pageData.alignment;

		return settings;

	};

	core.cacheGalleryPage = function(){

		var data = core.pageData,
			hasMusic = data.music && data.music.filePaths.length;

		core.addPage(data.pageID, data.alignment, function(){

			if (data.grid) {

				pbizPortfolio.gridLayout.init(data.grid);

				core.triggerCustomEvent({
					type: "page_ready",
					pageType: "gallery"
				});

			} else {

				core.data.isPageReady = false;

				slideshow.init(core.getSlideshowSettings(data));

			}

			if(hasMusic){
				music.init(data.pageID, data.music.filePaths, data.music.autoPlay, core.data.touch);
			}

		});

	};

	core.cacheInfoPage = function(){

		var data = core.pageData,
			hasImages = data.slideshow.images.length,
			hasMusic = data.music.filePaths.length;

		core.addPage(data.pageID, data.alignment, function(){

			pbizPortfolio.scroller.init();

			if(hasImages){

				core.data.isPageReady = false;

				slideshow.init(core.getSlideshowSettings(data));

			}

			else {

				core.triggerCustomEvent({
					type: "page_ready",
					pageType: "info"
				});

			}

			if(hasMusic){
				music.init(data.pageID, data.music.filePaths, data.music.autoPlay, core.data.touch);
			}

		});

	};

	// core.cacheContactPage = function(){

	// 	var data = core.pageData,
	// 		hasImages = data.slideshow.images.length,
	// 		hasContactForm = data.hasContactForm,
	// 		isFullWidth = !hasImages && !hasContactForm,
	// 		hasMusic = data.music.filePaths.length;

	// 	core.addPage(data.pageID, data.alignment, function(){

	// 		scroller.init(hasImages, isFullWidth);
	// 		contact.init();

	// 		if(hasImages){

	// 			core.data.isPageReady = false;
	// 			slideshow.init(core.getSlideshowSettings(data));

	// 		}

	// 		else {

	// 			core.triggerCustomEvent({
	// 				type: "page_ready",
	// 				pageType: "contact"
	// 			});

	// 		}

	// 		if(hasMusic){
	// 			music.init(data.pageID, data.music.filePaths, data.music.autoPlay, core.data.touch);
	// 		}

	// 	});

	// };

	// Updated to work with form builder. Reusing "contact" page type to
	// maximize backwards compatibility.
	core.cacheContactPage = function(){

		var data = core.pageData,
			hasImages = data.slideshow.images.length,
			hasMusic = data.music.filePaths.length;

		core.addPage(data.pageID, data.alignment, function(){

			pbizPortfolio.form.init();
			pbizPortfolio.scroller.init();

			if (hasImages) {

				core.data.isPageReady = false;
				slideshow.init(core.getSlideshowSettings(data));

			} else {

				core.triggerCustomEvent({
					type: "page_ready",
					pageType: "contact"
				});

			}

			if (hasMusic) {

				music.init(data.pageID, data.music.filePaths, data.music.autoPlay, core.data.touch);

			}

		});

	};

	core.cacheCalendarPage = function(){

		var data = core.pageData,
			colors = colorManager.getDefaultColors();

		core.addPage(data.pageID, data.alignment, function(){

			calendar.init(
				data.bookedDates,
				colors.calendar_blockbookedcolor,
				colors.calendar_blockbordercolor,
				colors.template_pagetitletextcolor,
				colors.calendar_textbookedcolor,
				data.hasBookingForm
			);

			pbizPortfolio.scroller.init();

			core.triggerCustomEvent({
				type: "page_ready",
				pageType: "calendar"
			});

		});

	};

	core.cacheListPage = function(){

		var data = core.pageData;

		core.addPage(data.pageID, data.alignment, function() {

			core.dom.body.removeClass("disable_music_0 disable_music_1");
			core.dom.body.addClass("disable_music_" + data.hasMusicDisabled);

			if (data.grid) {

				pbizPortfolio.gridLayout.init(data.grid);

				core.triggerCustomEvent({
					type: "page_ready",
					pageType: "list"
				});

			} else {

				pbizPortfolio.scroller.init();

				core.triggerCustomEvent({
					type: "page_ready",
					pageType: "list"
				});

			}

		});

	};

	core.cacheVideoPage = function(){

		var data = core.pageData;

		core.addPage(data.pageID, data.alignment, function() {

			var $videoWrappers = $("#content .video-wrapper");

			$videoWrappers.each(function(i, el) {

				// Fix for z-index issues.

				var $videoWrapper = $videoWrappers.eq(i),
					// jQuery .data does not work on object/embed elements
					$embedCode = $($videoWrapper.attr("data-embed-code")),
					iframeUrl,
					queryParam;

				if ($embedCode.is("iframe")) {

					// Handle iFrame.

                    // Strip Vimeo text links. Task 8003.

                    if ($embedCode.length > 1) {

                        // More markup than just iFrame was entered into the cpanel.

                        $embedCode = $($embedCode[0]);

                    }

					iframeUrl = $embedCode.attr("src");

					queryParam = (iframeUrl.indexOf("?") === -1 ? "?" : "&") + "wmode=transparent";

					$embedCode.attr("src", iframeUrl + queryParam);

				} else {

					// Handle old school object>embed markup.

					$embedCode
						.prepend('<param name="wmode" value="transparent">')
						.find("embed")
							.attr("wmode", "transparent");

				}

				$videoWrapper.html($embedCode);

			});

            // Always pause music if any is currently playing.
            if (music.data.initiated && !music.isPaused()) {

                music.pause();

            }

			pbizPortfolio.scroller.init();

			core.triggerCustomEvent({
				type: "page_ready",
				pageType: "video"
			});

		});

	};


	/**
	 *	Removes #!/
	 */

	core.cleanHash = function(str) {

		return str.replace(/[#\/!]+/, '');

	};

	/**
	 *	Parses a string to get the page #
	 */

	core.getPageFromString = function(str) {

		if (str.search(/page/i) === -1) {
			str = 'page/' + str;
		}

		return str.replace(/=\?/g, '/').match(/\/?page\/[0-9]+/i)[0].split('/')[1];

	};

	core.getPageNameFromString = function(str) {

		var nameArr = str.split("/");
		return nameArr.pop();

	};

	core.updateDomReference = function(key, update){
		core.dom[key] = update;
	};

	core.getVisibleLabel = function(pageId){
		var label = "visible",
			pageData = core.getPage(pageId);

		if(core.isPageHidden(pageId) || core.isPageHidden(pageData.parentID)){
			label = "hidden";
		}
		else if(core.isPageDisabled(pageId) || core.isPageDisabled(pageData.parentID)){
			label = "disabled";
		}

		return label;
	};

	core.isPageVisible = function(pageId){
		return core.getPage(pageId).visible == 1;
	};

	core.isPageHidden = function(pageId){
		return core.getPage(pageId).visible == 2;
	};

	core.isPageDisabled = function(pageId){
		return core.getPage(pageId).visible == 0;
	};

	core.getTopLevelAnchor = function(pageId){
		// _.log(pageId, $(".topLink[data-page=' " + pageId + " ']"));
	};

	core.removeCssHooks = function() {

		core.dom.body.attr("class", "");

	};

	core.updateCssHooks = function(){

		var pageData = core.getPage(),
			// currentClasses = core.dom.body.attr("class"),
			alignVals = ["", "left", "center", "right"],
			currentClasses = core.dom.body.attr("class");

		// if(currentClasses){
		// 	currentClasses = currentClasses.replace(/(visible_|type_|logo_align_)([^\s^\"])+/g, "");
		// }

		// core.dom.body.attr("class", $.trim(currentClasses));
		// core.dom.body.addClass("type_" + pageData.type);
		// core.dom.body.addClass("logo_align_" + alignVals[core.data.userSettings.logoAlign]);
		// core.dom.body.addClass("visible_" + core.getVisibleLabel());

		core.dom.body
			// .attr("class", "")
			.addClass(currentClasses.indexOf("image_count_") === -1 ? "image_count_0" : "")
			.addClass("align_" + pageData.align)
			.addClass("logo_align_" + alignVals[core.data.userSettings.logoAlign])
			.addClass("type_" + pageData.type)
			.addClass(core.data.userSettings.isBackgroundSet ? "cpanel-bg-img" : "no-cpanel-bg-img")
			.addClass("visible_" + core.getVisibleLabel());

		core.triggerCustomEvent({
			type: "core_css_hooks_added",
			pageData: core.getCurrentPage()
		});

	};

	// REFACTORING NOTE:
	// this method should be moved to slideshow module (hooks should be setup in slideshow.init() and based on JSON passed in to slideshow module (image data), NOT on unnecessary dom queries)
	// core.updateSlideshowHooks = function(){

	// 	var pageData = core.getPage(),
	// 		currentClasses = core.dom.body.attr("class"),
	// 		$viewer = $("#viewer"),
	// 		$thumbContainer = $("#thumbContainer"),
	// 		hasThumbs = $thumbContainer.length > 0;

	// 	if(currentClasses){
	// 		currentClasses = currentClasses.replace(/($align_|image_count_|thumbs_)([^\s^\"])+/g, "");
	// 	}

	// 	core.dom.body.attr("class", $.trim(currentClasses));
	// 	core.dom.body.addClass("align_" + pageData.align);
	// 	core.dom.body.addClass("image_count_" + $viewer.children().length);
	// 	core.dom.body.addClass( hasThumbs ? "thumbs_visible" : "thumbs_invisible");

	// };

	$(document).on("mouseenter", ".no-touch a,.touch a:not('.elink')", function(){
		$(this).addClass("hover"); // handy for some js stuff
	});

	$(document).on("mouseleave", ".no-touch a,.touch a:not('.elink')", function(){
		$(this).removeClass("hover");
	});

	$(document).on("click", "#backToMobile", function(e){
		$.cookie("BYPASSMOBILE", false);
		// console.log("core.data.domain: " + core.data.domain);
		window.location = "http://" + core.data.domain + "/?loadType=mobile&" + core.data.previewMode;
		e.preventDefault();
	});

	core.pageHasGallerySupport = function(pageId){
		return colorManager.pageHasColorShiftingSupport(pageId);
	}



	/* MENU related */

	core.alignSubWrapper = function($menuItem, $subWrapper){

		if (core.data.autoAlignSubWrapper) {

		  var menuItemOuterWidth = $menuItem.outerWidth();
		  var menuItemLeft = $menuItem.offset().left;

		  var subWrapperOuterWidth = $subWrapper.outerWidth();

		  if (template.data.menu.subWrapperAlign === "left"){
		    $subWrapper.css({ left: menuItemLeft }); // align sub menu to left edge of main menu item
		  }
		  else if(template.data.menu.subWrapperAlign === "flushRight"){
			$subWrapper.css({ left: menuItemOuterWidth + 1 });
		  }
		  else{ // default to "center" - center sub menu underneath main menu item
		    $subWrapper.css({ left: menuItemLeft - ((subWrapperOuterWidth - menuItemOuterWidth) / 2) });
		  }

		}

	};

	core.setMenuWidths = function(){
	  var i;

	  $("a.ddmenu").each(function(i){ // find each main menu item that contains a sub menu
	    var $menuItem = $(this),
	    	menuItemOuterWidth = $menuItem.outerWidth(),
	    	$subWrapper = $(core.dom.subwrappers[i]),
	    	subWrapperOuterWidth = $subWrapper.outerWidth();

	    if (subWrapperOuterWidth > menuItemOuterWidth){ // sub menu wrapper is wider than the main menu item

	      if (template.data.menu.topMatchSubWidth){ // make the main menu item width match the sub menu

	        var menuItemPadBord = menuItemOuterWidth - $menuItem.width(),
	        	newWidth = subWrapperOuterWidth - menuItemPadBord;

	        $menuItem.css({ width: newWidth });

	      }
		}
		else{ // main menu item is wider than the sub menu.

			if(template.data.menu.subMatchTopWidth){ // make the sub menu wrapper width match the main menu item.

				var subWrapperPadBord = subWrapperOuterWidth - $subWrapper.width();
	        	var newWidth = menuItemOuterWidth - subWrapperPadBord;

			    $subWrapper.css({ width: newWidth });
	      	}
		}
	  });
	};

	core.setupMenuBg = function(){

		core.dom.menuBackground.prependTo(core.dom.body);

		core.dom.menuBackground.css({
			opacity: 1,
			height: core.dom.activeParentMenu.outerHeight(),
			width: 0
		});

	};

	core.setupMenuHover = function(){


		if(core.data.activateHover){

			core.dom.menuHover.prependTo(core.dom.body);

			if(template.setupMenuHover){

				template.setupMenuHover();

			}
			else{

				core.dom.menuHover.css({
					height: core.dom.activeParentMenu.outerHeight(),
					top: core.dom.activeParentMenu.offset().top,
					opacity: 0,
					width: core.dom.activeParentMenu.outerWidth(),
					left: core.dom.activeParentMenu.offset().left
				});

				$.each(core.dom.menuLinks, function(i, el) {

					var $el = $(el);

					if(!core.data.touch || (core.data.touch && !$el.is(".elink"))){

						$el.hover(function() {

							core.dom.menuHover.queue('slider', function() {

								$(this).stop().animate({
									opacity: 1,
									left: $el.offset().left,
									top: $el.offset().top,
									width: $el.outerWidth(),
									height: $el.outerHeight()
								}, { duration: 200, queue: false });

							}).dequeue('slider');

						}, function() {

							core.dom.menuHover.stop('slider');

						});

					}

				});

				core.dom.menu.find('.topLink').mouseleave(function() {

					core.dom.menuHover.stop().animate({
						opacity: 0,
						left: core.dom.activeParentMenu.offset().left,
						top: core.dom.activeParentMenu.offset().top,
						width: core.dom.activeParentMenu.outerWidth(),
						height: core.dom.activeParentMenu.outerHeight()
					}, 300);

				});

			}

		}
	};

	core.hideAllSubMenus = function(){

		var $activeSub = $(".sub-wrapper.active"),
			$topLink = $(".topLink[data-expanded='true']");


			core.dom.subwrappers.removeClass("active");
			$(".ddmenu").attr("data-expanded", false);

			if(!template.hideAllSubMenus && !template.menuHideSub){
				core.dom.subwrappers.hide();
				$(document).trigger({ type: "menu_subs_hidden", sub: $activeSub, topLink: $topLink });
			}
			else{
				if(template.hideAllSubMenus){
					template.hideAllSubMenus();
				}
				else if(template.menuHideSub){
					template.menuHideSub($activeSub);
				}
			}

	};

	core.deselectAllMenuItems = function(){
		// core.dom.menuBackground.animate({ opacity: 0 });
		// core.dom.menuHover.animate({ opacity: 0 });
		core.dom.menuLinks.removeAttr("data-selected").removeClass("selected-parent");
		core.dom.submenuLinks.removeAttr("data-selected").removeClass("selected-child");
	};

	core.showSubMenu = function($parentLink, $subMenu, isMenuExpanded){

		var isMenuCollapsed = isMenuExpanded === "false",
			$subWrapper = $subMenu.closest(".sub-wrapper");

		core.hideAllSubMenus();
		core.alignSubWrapper($parentLink, $subWrapper);

		if(isMenuCollapsed){
			$parentLink.attr("data-expanded", true);
			$subWrapper.addClass("active");

			if(template.menuShowSub){
				template.menuShowSub($parentLink, $subMenu);
			}

			if(core.data.touch){
				$parentLink.trigger("mouseover");
			}
		}

	};

	core.triggerCustomEvent = function(eventObj) {

		var $document = core.dom.getElement({key: "document"});

		$document.triggerHandler(eventObj);

	};

	$(document).on("click", ".elink", function(){
		core.hideAllSubMenus();
	});

	$(document).on("click", ".topLink:not('.ddmenu,.elink'),.subLink:not('.elink')", function(){ // clicking an endpoint

		var $subLinks = $(".subLink").not(this),
			$this = $(this),
			isCaption = $this.is("#captionContainer"),
			$this = isCaption ? $("a[data-page=" + $this.attr("data-menu-id")) : $this,
			isSubLink = $this.is(".subLink"),
			isExternalLink = $this.is(".elink");

		$subLinks.removeClass("selected-child");
		core.dom.menuLinks.removeClass("selected-parent");
		core.hideAllSubMenus();

		if(isSubLink){
			if(!isExternalLink){
				$this.closest(".topLi").find(".topLink").addClass("selected-parent");
				$this.addClass("selected-child");
			}
		}
		else{
			$this.addClass("selected-parent");
		}

	});

	$(document).on("click", ".ddmenu", function(e){

		var triggerType = template.data.menu.dropdownTrigger !== "undefined" ? template.data.menu.dropdownTrigger : "hover",
			clickIsTrigger = triggerType === "click" || core.data.touch,
			$this = $(this),
			$subMenu = $this.parents(".topLi").find(".subMenu");

		if(clickIsTrigger){
			core.showSubMenu($this, $subMenu, $this.attr("data-expanded"));
		}

		e.preventDefault();

	});

	$(document).on("mouseenter", ".ddmenu", function(){

		var triggerType = template.data.menu.dropdownTrigger !== "undefined" ? template.data.menu.dropdownTrigger : "hover",
			hoverIsTrigger = triggerType === "hover" && !core.data.touch,
			$this = $(this),
			$subMenu = $this.parents(".topLi").find(".subMenu");

		if(hoverIsTrigger){
			clearTimeout(core.data.menuHoverTimeout);
			core.showSubMenu($this, $subMenu, $this.attr("data-expanded"));
		}

	});

	$(document).on("mouseleave", ".ddmenu,.sub-wrapper", function(){

		var triggerType = template.data.menu.dropdownTrigger !== "undefined" ? template.data.menu.dropdownTrigger : "hover",
			hoverIsTrigger = triggerType === "hover" && !core.data.touch;

		if(hoverIsTrigger){
			core.data.menuHoverTimeout = window.setTimeout(core.hideAllSubMenus, core.data.submenuHideDelay);
		}

	});

	$(document).on("mouseenter", ".sub-wrapper", function(){
		var triggerType = template.data.menu.dropdownTrigger !== "undefined" ? template.data.menu.dropdownTrigger : "hover",
			hoverIsTrigger = triggerType === "hover" && !core.data.touch;

		if(hoverIsTrigger){
			clearTimeout(core.data.menuHoverTimeout);
		}
	});

	$(document).bind("core_page_injected", function(){

		core.dom.menuLinks.removeAttr("data-selected");
		core.dom.submenuLinks.removeAttr("data-selected");

		// this will be true if the active menu IS the active parent menu
		// OR the active menu is a CHILD of he active parent menu
		if(core.dom.activeParentMenu.closest(".topLi").find(core.dom.activeMenu).length) {

			// in order to get hidden pages and friendly urls to work as expected
			// activeMenu and activeParent menu kinda get "hacked to pieces"
			// so we need to make sure that the values that were set actually
			// appear in the dom before setting them to a "selected state"
			core.dom.activeParentMenu.addClass("selected-parent").attr("data-selected", "true");

		}

		core.dom.activeMenu.attr("data-selected", "true");

		if(core.dom.activeMenu.is(".subLink")){
			core.dom.activeMenu.addClass("selected-child");
		}

	});

	$(document).on("contextmenu", "body", function(e) {
		if(!core.data.devmode && e.which === 3){ // if we're not in "devmode" and user has right clicked, intercept event
			return false;
		}
	});

	$(document).on("template_intro_complete", function(){

		if(core.isPageHidden()){
			core.deselectAllMenuItems();
		}

	});

}).call(global, module, undefined, undefined);
